<template>
  <div class="main-container">
    <div class="banner">
      <span class="banner-title">矩尺网络</span>
      <span class="banner-description">一家服务器负载均衡技术与服务提供商</span>
    </div>
    <div class="about-us">
      <div class="about-us-hash" :name="$store.state.aboutUsElementName"></div>
      <h1>矩尺网络&nbsp;&nbsp;|&nbsp;&nbsp;公司简介</h1>
      <div class="title-devide-line"></div>
      <div class="about-us-content">
        <p class="black-strong">杭州矩尺网络科技有限公司（Hangzhou Normaedelta Networks & Services Ltd.）是一家领先的分布式应用交付解决方案提供商。</p>
        <p>从投资到管理，从设计到平台，从软著到IP，从技术模块到系统，从代码到产品，我们实现了全国产，全自研，全自主可控。(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)等。</p>
        <p>这是一群有理想有梦想有情怀的年轻人，在一个细分的领域，引进吸收全球领先的理念，前瞻，和技术，完成全部本土实现；追求为中国的IT技术演进和发展，信息技术安全和创新，做出一些自己微薄的贡献。</p>
        <p>我们基于开放的技术和平台，我们乐于与合作伙伴一起出品符合你们行业要求的产品。</p>
      </div>
      <div class="keywrods">
        <div class="keywords-wrap">
          <span>2021年</span>
          <span>成立</span>
        </div>
        <div class="keywords-wrap">
          <span>负载均</span>
          <span>衡技术</span>
        </div>
        <div class="keywords-wrap">
          <span>服务</span>
          <span>提供商</span>
        </div>
      </div>
    </div>
    <div class="contact-us">
      <TitlePoint title="联系我们" />
      <div class="info">
        <div>
          <span class="label">公司地址：</span>
          <span class="content">浙江省杭州市滨江区滨盛路1509号天恒大厦5楼507</span>
        </div>
        <div>
          <span class="label">联系电话：</span>
          <span class="content">0571-86833186</span>
        </div>
        <div>
          <span class="label">邮箱：</span>
          <span class="content">business@n6delta.com</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitlePoint from '@/components/TitlePoint.vue'
import { scrollTo } from '@/utils'

export default {
  components: {
    TitlePoint
  },
  watch: {
    $route: {
      handler(val) {
        const scroll = val.query.scroll
        if(scroll) {
          this.$nextTick(() => {
            scrollTo(this.$store.state.aboutUsElementName)
            setTimeout(() => {
              this.$store.commit('Set_ShowNav', true)
            }, 0)
          })
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.banner{
  width: 1440px;
  height: 960px;
  background: url('~@/assets/imgs/introduction-banner-bg.jpg') 100% no-repeat;
  position: relative;
  .banner-title{
    position: absolute;
    bottom: 250px;
    left: 100px;
    display: block;
    color: $primaryColor;
    font-size: 75px;
  }
  .banner-description{
    position: absolute;
    bottom: 180px;
    left: 100px;
    display: block;
    color: #333333;
    font-size: 45px;
  }
}
.about-us {
  position: relative;
  background-color: #fff;
  padding: 50px 70px;
  padding-bottom: 90px;
  &::before {
    content: 'About  US';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 85px;
    font-weight: 700;
    color: #F5F5F5;
  }
  .about-us-hash{
    position: absolute;
    top: -75px;
  }
  h1{
    position: relative;
    z-index: 1;
    margin-top: 62px;
    margin-bottom: 10px;
  }
  .title-devide-line{
    margin: 0 auto;
    width: 155px!important;
  }
  .black-strong{
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 50px;
  }
  .about-us-content {
    // width: 1000px;
    margin: 0 auto;
    p{
      margin: 20px 0;
      text-align: left;
      // text-indent: 2rem;
    }
  }
  .keywrods{
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .keywords-wrap{
      width: 125px;
      height: 125px;
      background: url('~@/assets/imgs/introduction-circle.svg') no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #0059A3;
      font-size: 25px;
      &>span{
        display: block;
      }
    }
  }
}
.contact-us {
  padding: 50px 70px;
  height: 390px;
  font-size: 15px;
  background: url('~@/assets/imgs/contact-us-bg.png') no-repeat;
  .info{
    margin: 0 auto;
    width: 430px;
    text-align: left;
    &>div{
      margin: 40px 0;
    }
    .label{
      color: #6F6F6F;
      display: inline-block;
      width: 75px;
    }
  }
}
</style>